import React, { useEffect } from "react";

import Card from "../../../common/sharedComponents/Card";
import {
  RBWhiteCardTwo,
  Seperator,
  StepHolder,
  TitleHolder,
} from "../../../common/sharedComponents/styles/card.style";
import RBCard from "../../../common/sharedComponents/RBCard";
import styled from "styled-components";
import info from "../../../assets/icon.png";

import { FormLinkHeader } from "../../../assets/fonts/typography.style";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getDashboard, getBanks } from "../redux/dashboardSlice";
// import { bank_info } from "../../../utilities/helper";

const VendorRegCard = ({ navigate, vendorDetails }) => {
  return (
    <RBCard>
      <CardRow>
        <CardColumn>Vendor Registration</CardColumn>
        <CardColumn>
          <CardButtonContainer>
            {vendorDetails !== null ? (
              vendorDetails.vendor_code !== "" ? (
                <button
                  onClick={() =>
                    navigate(
                      `/app/vendor-registration-view/${vendorDetails.id}`
                    )
                  }
                >
                  Manage
                </button>
              ) : (
                ""
              )
            ) : (
              <button onClick={() => navigate(`/app/vendor-registration`)}>
                Register
              </button>
            )}
          </CardButtonContainer>
        </CardColumn>
      </CardRow>
      <Grid sx={{ textAlign: "left" }} container>
        <Grid sx={{ paddingLeft: "2em" }} item xs={12} sm={2} md={3}>
          <Light>Status</Light>
          <Dark style={{ textTransform: "capitalize" }}>
            {vendorDetails ? (
              vendorDetails.is_pending === true ? (
                "Pending"
              ) : vendorDetails.is_rejected === true ? (
                <spna style={{ color: "red" }}>Rejected</spna>
              ) : (
                <spna style={{ color: "green" }}>Approved</spna>
              )
            ) : (
              "Not Registered"
            )}
          </Dark>
        </Grid>
        <Grid sx={{ paddingLeft: "2em" }} item xs={12} sm={2} md={3}>
          <Light>Vendor Code</Light>
          <Dark style={{ textTransform: "capitalize" }}>
            {vendorDetails
              ? vendorDetails.vendor_code == null
                ? "Not Registered"
                : vendorDetails.vendor_code
              : "Not Registered"}
          </Dark>
        </Grid>
      </Grid>
    </RBCard>
  );
};

const BankDetailsCard = ({ navigate, banks, vendorDetails }) => {
  /*   const { bank_name, branch_name, account_number } = item; */
  var disabledCard = "";
  // if (!bank_info) {
  //   if (vendorDetails) {
  //     if (vendorDetails?.vendor_code) {
  //       disabledCard = "disabledCard";
  //     }
  //   }
  // }
  return (
    <>
      <RBWhiteCardTwo className={disabledCard}>
        <CardRow>
          <CardColumn>Bank Information</CardColumn>
          <CardColumn>
            <CardButtonContainer>
              {vendorDetails || vendorDetails?.vendor_code ? (
                <button onClick={() => navigate(`/app/bank-details`)}>
                  Manage
                </button>
              ) : (
                // <button className={"btn" + disabledCard}>Manage</button>
                ""
              )}
            </CardButtonContainer>
          </CardColumn>
        </CardRow>
        <Grid sx={{ textAlign: "left" }} container>
          <Grid sx={{ paddingLeft: "1em" }} item xs={12} sm={2} md={3}>
            <Light>Bank</Light>
            <Dark>
              {banks
                ? banks?.[0]?.bank_name == null
                  ? "Not Applicable"
                  : banks?.[0]?.bank_name
                : "Not Applicable"}
            </Dark>
          </Grid>
          <Grid sx={{ paddingLeft: "1em" }} item xs={12} sm={2} md={3}>
            <Light>Account Number</Light>
            <Dark>
              {banks
                ? banks?.[0]?.account_number == null
                  ? "Not Applicable"
                  : banks?.[0]?.account_number
                : "Not Applicable"}
            </Dark>
          </Grid>
          <Grid sx={{ paddingLeft: "1em" }} item xs={12} sm={2} md={3}>
            <Light>Branch</Light>
            <Dark>
              {banks
                ? banks?.[0]?.branch_name == null
                  ? "Not Applicable"
                  : banks?.[0]?.branch_name
                : "Not Applicable"}
            </Dark>
          </Grid>
        </Grid>
      </RBWhiteCardTwo>
    </>
  );
};

const DashboardContent = () => {
  const { vendorDetails, banks } = useSelector(
    (state) => state.dashboarddetails
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const value = JSON.parse(localStorage.getItem("tata_login_user"));

  const vendorRegInfo = (payload) => {
    dispatch(getDashboard(payload));
  };

  const bankDetailsInfo = (payload) => {
    dispatch(getBanks(payload));
  };

  useEffect(() => {
    vendorRegInfo();
    bankDetailsInfo();
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      <TitleHolder>Dashboard</TitleHolder>
      <Card>
        <DashBoardRow>
          <DashBoardColumn>
            Welcome{" "}
            <b
              style={{
                textTransform: "capitalize",
                fontFamily: "var(--font-family-semi-bold)",
              }}
            >{`${value.first_name}!`}</b>
          </DashBoardColumn>
          <DashBoardColumn>
            PAN:
            <b
              style={{
                fontFamily: "var(--font-family-semi-bold)",
              }}
            >
              {"\u000a"}
              {typeof value.PAN_number !== undefined &&
              value.legal_name !== null
                ? `${value.PAN_number}`
                : `International`}
            </b>
          </DashBoardColumn>
        </DashBoardRow>
        <Seperator />
        <Instructions>
          <img src={info} alt="info" /> Instructions
        </Instructions>
        <br />
        <br />
        <ListHolder>
          <ul>
            <li>
              New vendors should complete vendor registration first. Submit your
              request by clicking "Register" on the first card.
            </li>
            <li>
              Bank information card will be disabled until the vendor
              registration request is submitted.
            </li>
            <li>
              For MSME validation: The company name on the PAN and the MSME name
              should be the same.
            </li>
            <br />
          </ul>
        </ListHolder>
      </Card>
      <SubTitleHolder>{"Registration & Bank Details"}</SubTitleHolder>

      <VendorRegCard navigate={navigate} vendorDetails={vendorDetails} />
      <StepHolder>
        <BankDetailsCard
          navigate={navigate}
          banks={banks}
          vendorDetails={vendorDetails}
        />
      </StepHolder>
    </div>
  );
};

export default DashboardContent;

export const DashBoardRow = styled.div`
  font-family: var(--font-fmaily-reg);
  ::after {
    font-family: var(--font-family-reg);
    content: "";
    display: table;
    clear: both;
  }
`;

export const DashBoardColumn = styled.div`
  :nth-child(2) {
    padding: 20px;
    font-size: 14px;
    color: #212134;
    font-weight: 400;
    font-family: var(--font-family-reg);
    float: right;
    text-align: right;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  padding: 15px;
  font-weight: 400;
  font-size: 20px;
  color: #212134;
  font-family: var(--font-family-reg);
  text-align: left;
  float: left;
  width: 50%;
  /*padding: 0px 15px 0px 0px; */
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const SubTitleHolder = styled.div`
  font-family: var(--font-family-med);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  position: relative;
  padding: 1em 0em 1em 0em;
  margin: 0 0 0 22%;
  color: #4a4a6a;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: auto;
`;

export const Instructions = styled.div`
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  padding: 1em 2em 1em 2em;
  text-align: left;
  float: left;
  img {
    margin-right: 8px;
  }
`;

export const ListHolder = styled.div`
  padding-left: 3em;
  li::marker {
    color: #666687;
  }
  li {
    /* :nth-child(3) {
      padding-bottom: 15px;
    } */
    color: #32324d;
    padding-bottom: 5px;
    font-family: var(--font-family-reg);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }
`;

export const CardRow = styled.div`
  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const CardColumn = styled.div`
  :nth-child(2) {
    padding: 20px;
    float: right;
    text-align: right;
  }
  :nth-child(1) {
    font-family: var(--font-family-semi-bold);
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    padding: 25px;
  }
  padding: 20px;
  text-align: left;
  float: left;
  width: 50%;
  padding: 0px 15px 0px 0px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const CardTitleHolder = styled.div`
  padding: 40px;
`;

export const CardButtonContainer = styled.div`
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* padding-bottom: 2em; */
  button {
    width: 140px;
    height: 40px;
    color: #ffffff;
    border: none;
    background: #1cb697;
    border-radius: 4px;

    :hover {
      background-color: #1cb;
      font-size: 16.5px;
      transition: all 0.3ms ease-in;
    }
  }
  ${FormLinkHeader} {
    font-weight: 600;
    font-size: 16px;
    :hover {
      font-size: 16.5px;
      transition: all 0.3ms ease-in;
    }
  }
`;

export const Light = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 5px;
  color: #32324d;
`;

export const Dark = styled.div`
  padding-bottom: 2em;
  font-family: var(--font-family-semi-bold);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #212134;
`;
