import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const vendor_reg = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_role = user.roles[0];

  return apiClient.post(
    vendor_role === "user"
      ? `${apiClient.Urls.vendor_reg}`
      : vendor_role === "officer"
      ? `${apiClient.Urls.vendor_reg_officer}`
      : `${apiClient.Urls.vendor_reg}`,
    payload,
    true,
    "file"
  );
};

export const goodsService = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_role = user.roles[0];
  return apiClient.get(
    vendor_role === "user"
      ? `${apiClient.Urls.goodsService}?service=${payload}`
      : `${apiClient.Urls.goodsServiceOfficer}?service=${payload}`,
    "",
    true
  );
};

export const categories = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_role = user.roles[0];
  return apiClient.get(
    vendor_role === "user"
      ? `${apiClient.Urls.categories}?category=${payload}`
      : `${apiClient.Urls.categoriesOfficer}?category=${payload}`,
    "",
    true
  );
};
export const getItems = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_role = user.roles[0];
  return apiClient.get(
    vendor_role === "user"
      ? `${apiClient.Urls.getItem}`
      : `${apiClient.Urls.getItemOfficer}`,
    payload,
    true
  );
};

export const itemcategories = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_role = user.roles[0];
  return apiClient.get(
    vendor_role === "user"
      ? `${apiClient.Urls.subcategories}?subCategory=${payload}`
      : `${apiClient.Urls.subcategoriesOfficer}?subCategory=${payload}`,
    "",
    true
  );
};

export const contact_add = (payload) => {
  return apiClient.post(`${apiClient.Urls.contactSave}`, payload, true);
};

export const add_business_loc = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_role = user.roles[0];
  return apiClient.post(
    vendor_role === "user"
      ? `${apiClient.Urls.add_business}`
      : `${apiClient.Urls.add_business_officer}`,
    payload,
    true,
    "file"
  );
};

export const view_vendor_details = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_role = user.roles[0];

  return apiClient.get(
    vendor_role === "super admin" ||
      vendor_role === "finance" ||
      vendor_role === "Admin"
      ? `${apiClient.Urls.view_admin_details}/${payload}`
      : vendor_role === "user"
      ? `${apiClient.Urls.view_details}/${payload}`
      : `${apiClient.Urls.view_officer_details}/${payload}`,
    "",
    true
  );
};

export const vendor_reg_update = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.vendor_reg_update}/${payload.vendor_id}`,
    payload,
    true,
    "file"
  );
};

export const approve_vendor = (payload) => {
  const userRole = LocalStorage.getItem("tata_login_user").roles[0];
  const tenant_id = LocalStorage.getItem("client")?.id;

  return apiClient.patch(
    userRole === "finance" &&
      tenant_id !== 5 &&
      tenant_id !== 2 &&
      tenant_id !== 3
      ? `${apiClient.Urls.vendor_approve}/${payload.vendor_id}?type=bank`
      : tenant_id !== 5 && tenant_id !== 2 && tenant_id !== 3
      ? `${apiClient.Urls.vendor_approve}/${payload.vendor_id}`
      : userRole !== "officer"
      ? `${apiClient.Urls.vendor_approves}/${payload.vendor_id}`
      : `${apiClient.Urls.officer_vendor_approves}/${payload.vendor_id}`,
    payload,
    true
  );
};
export const validatePan = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.validate_pan_officer}`,
    payload,
    true
  );
};

export const createuser = (payload) => {
  return apiClient.post(`${apiClient.Urls.createuser}`, payload, true);
};

export const update_business_loc = (payload) => {
  return apiClient.patch(`${apiClient.Urls.update_business}`, payload, true);
};

export const upload_gst_doc = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.upload_gst_doc}`,
    payload,
    true,
    "file"
  );
};

export const banksApi = (payload) => {
  var user_role = LocalStorage.getItem("tata_login_user").roles[0];
  return apiClient.get(
    user_role !== "officer"
      ? `${apiClient.Urls.adminBankinformation}/${payload}`
      : `${apiClient.Urls.officerBankinformation}/${payload}`,
    "",
    true
  );
};

export const validate_gst = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const vendor_role = user.roles[0];
  return apiClient.post(
    vendor_role === "user"
      ? `${apiClient.Urls.validate_gst}`
      : vendor_role === "officer"
      ? `${apiClient.Urls.validate_gst_officer}`
      : `${apiClient.Urls.validate_gst}`,
    payload,
    true
  );
};
