import { CardContent, Grid, Typography, TableHead } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AdminTitleHolder } from "../../../common/sharedComponents/styles/card.style";
import { getWorkflowDetails } from "../redux/userWorkflowsSlice";
import { styled as materialStyles } from "@mui/material/styles";
import Card from "@mui/material/Card";
import styled from "styled-components";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { capitalizeString } from "../../../utilities/helper";
import LocalStorage from "../../../utilities/localStorage";
import { CompanyLabel } from "../../../CONSTANTS";
import moment from "moment";

function UserWorkflowsViewContent() {
  const { workflow_details } = useSelector((state) => state.userWorkflows);
  const params = useParams();
  const dispatch = useDispatch();

  const workflow_id = params.workflowid;
  const viewWorkflowDetails = (workflow_id) => {
    dispatch(getWorkflowDetails(workflow_id));
  };

  const ClientId = LocalStorage.getItem("client").id;
  const Company = CompanyLabel(ClientId);

  useEffect(() => {
    viewWorkflowDetails(workflow_id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AdminTitleHolder>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Workflow
          </Grid>
        </Grid>
      </AdminTitleHolder>
      <StyledCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className="label">Name</Typography>
              <VendorCardLabel>
                {workflow_details.name
                  ? capitalizeString(workflow_details.name)
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">{Company} Name</Typography>
              <VendorCardLabel>
                {workflow_details?.company_name
                  ? capitalizeString(workflow_details?.company_name)
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">Description</Typography>
              <VendorCardLabel>
                {workflow_details?.description
                  ? workflow_details?.description
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            {ClientId === 5 ? (
              <>
                <Grid item xs={6} md={6}>
                  <Typography className="label">PURCHASE POINT</Typography>
                  <VendorCardLabel>
                    {workflow_details?.CompanyPurchasePoint
                      ? capitalizeString(
                          workflow_details?.CompanyPurchasePoint
                            ?.PurchasePointMaster?.purchase_point
                        )
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className="label">RANGE</Typography>
                  <VendorCardLabel>
                    {workflow_details?.RangeMaster?.range
                      ? workflow_details?.RangeMaster?.range
                      : "Not Applicable"}
                  </VendorCardLabel>
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid item xs={6} md={6}>
              <Typography className="label">Workflow Category</Typography>
              <VendorCardLabel>
                {workflow_details?.workflow_category
                  ? workflow_details?.workflow_category
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography className="label">Date</Typography>
              <VendorCardLabel>
                {workflow_details?.createdAt
                  ? moment(workflow_details?.createdAt).format("DD MMM YY")
                  : "Not Applicable"}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <VendorCardTitle>Approver Sequence</VendorCardTitle>
          <CustomTableContainer component={Card}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#e1ebfa",
                  }}
                >
                  <TableCell width="10%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Step No.</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Name</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Email</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Remarks</TableHeaderFont>
                  </TableCell>
                  <TableCell width="20%" style={{ textAlign: "center" }}>
                    <TableHeaderFont>Date</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workflow_details?.WorkflowSteps ? (
                  workflow_details.WorkflowSteps.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.step !== null ? row.step : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.name !== null ? row.name : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.contact_person_email !== null
                            ? row.contact_person_email
                            : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row?.remarks ? row?.remarks : "Not Applicable"}
                        </TableCell>
                        <TableCell
                          width="20%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {row.createdAt
                            ? moment(row.createdAt).format("DD MMM YY")
                            : "Not Applicable"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </CardContent>
      </StyledCard>
    </>
  );
}

export default UserWorkflowsViewContent;

export const StyledCard = materialStyles(Card)`
  box-shadow: 0px 0px 20px rgba(127, 161, 250, 0.15);
  flex-direction: column;
  border: none;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1em;
  `;

export const VendorCardLabel = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #32324d;
`;

export const VendorCardTitle = styled.div`
  text-align: left;
  padding: 0px 20px 15px 0px;
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 18px;
  color: #212134;
`;
