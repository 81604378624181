import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_workflows = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_workflows}`, payload, true);
};

export const get_workflow_category_list = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.get_workflow_category_list}`,
    payload,
    true
  );
};

export const get_range_list = (payload) => {
  return apiClient.get(`${apiClient.Urls.get_range_list}/${payload}`, "", true);
};

export const workflows = (payload) => {
  return apiClient.post(`${apiClient.Urls.workflows}`, payload, true, "");
};

export const view_WorkflowDetails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.view_workflow_details}/${payload}`,
    "",
    true
  );
};

export const view_PrWorkflowDetails = () => {
  var user = LocalStorage.getItem("tata_login_user");
  var roles = user.roles;
  var role = roles[0];
  return apiClient.get(
    role === "super admin"
      ? `${apiClient.Urls.view_pr_workflow_details_sa}`
      : role === "officer"
      ? `${apiClient.Urls.view_pr_workflow_details}`
      : `${apiClient.Urls.admin_view_pr_workflow_details}`,
    "",
    true
  );
};

export const workflow_steps = (payload) => {
  return apiClient.post(`${apiClient.Urls.workflow_steps}`, payload, true);
};
export const update_workflow_steps = (payload) => {
  return apiClient.patch(`${apiClient.Urls.workflow_steps}`, payload, true);
};

export const get_approvers = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  var roles = user.roles;
  var role = roles[0];
  return apiClient.get(
    role === "super admin"
      ? `${apiClient.Urls.get_approvers_sa}`
      : role === "officer"
      ? `${apiClient.Urls.get_approvers}`
      : `${apiClient.Urls.admin_get_approvers}`,
    payload,
    true
  );
};

export const get_persons = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  var roles = user.roles;
  var role = roles[0];
  return apiClient.get(
    role === "super admin"
      ? `${apiClient.Urls.get_persons_sa}?company_id=${payload ?? ""}`
      : role === "officer"
      ? `${apiClient.Urls.get_persons}?company_id=${payload ?? ""}`
      : `${apiClient.Urls.admin_get_persons}?company_id=${payload ?? ""}`,
    payload,
    true
  );
};

export const get_pr_creation_status = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  var roles = user.roles;
  var role = roles[0];
  return apiClient.get(
    role !== "officer"
      ? `${apiClient.Urls.get_pr_creation_status_sa}`
      : `${apiClient.Urls.get_pr_creation_status}`,
    payload,
    true
  );
};

export const check_workflow_step_api = (payload) => {
  return apiClient.get(
    `${apiClient?.Urls.check_workflow_step_are_defind}?workflow=${payload?.wf_name}&cid=${payload?.cid}`,
    "",
    true
  );
};
