import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  //   IconButton,
  Pagination,
  //   Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
// for EmptyContainer
import empty from "../../../assets/illo.png";
import {
  ButtonLink,
  DisplayText,
} from "../../bankDetails/components/bankAccountsContent";
import { useDispatch, useSelector } from "react-redux";
import { getItems } from "../redux/itemSlice";
import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { RemoveRedEye } from "@mui/icons-material";
// import LocalStorage from "../../../utilities/localStorage";
const EmptyContainer = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <br />
      <img src={empty} alt="empty" />

      <DisplayText>There are no Items to display</DisplayText>
      <br />
      <ButtonLink to="/app/add-item">+ Add Items</ButtonLink>
      <br />

      <br />
    </Grid>
  );
};

function ItemTableContent({ search, clicked, setClicked }) {
  const [page, setPage] = React.useState(1);
  const [per_page, setPer_page] = React.useState(10);
  const [reload, setreload] = React.useState(false);

  const { itemsData, pagination, loading } = useSelector((state) => state.item);

  //   const ClientId = LocalStorage.getItem("client")?.id;

  const dispatch = useDispatch();

  const getItem = () => {
    const payload = {
      per_page,
      page,
      search,
    };
    dispatch(getItems(payload));
    setClicked(false);
    setreload(false);
  };

  useEffect(() => {
    getItem();
    // eslint-disable-next-line
  }, [page, per_page, clicked, reload]);
  useEffect(() => {
    setPage(1);
  }, [per_page, clicked]);

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <CustomTableContainer component={Card}>
      <Table aria-label="simple table">
        <CustomTableHead>
          <TableRow>
            <TableCell>
              <TableHeaderFont>Item Name</TableHeaderFont>
            </TableCell>
            <TableCell>
              <TableHeaderFont>Item Code</TableHeaderFont>
            </TableCell>
            <TableCell>
              <TableHeaderFont>UOM</TableHeaderFont>
            </TableCell>
            <TableCell>
              <TableHeaderFont>HSN/SAC Code</TableHeaderFont>
            </TableCell>
            <TableCell>
              <TableHeaderFont>Date Created</TableHeaderFont>
            </TableCell>
            {/* <TableCell>
              <TableHeaderFont>Action</TableHeaderFont>
            </TableCell> */}
          </TableRow>
        </CustomTableHead>
        <TableBody>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : itemsData.length !== 0 ? (
            itemsData.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <TableContentFont>
                      {row.Description !== null
                        ? row.Description
                        : "Not Applicable"}
                    </TableContentFont>
                  </TableCell>

                  <TableCell>
                    <TableContentFont>
                      {row.MaterialCode !== null
                        ? row.MaterialCode
                        : "Not Applicable"}
                    </TableContentFont>
                  </TableCell>

                  <TableCell>
                    <TableContentFont>
                      {row.UOM !== null ? row.UOM : "-"}
                    </TableContentFont>
                  </TableCell>

                  <TableCell>
                    <TableContentFont>
                      {row?.HsnSacCode?.item ? row.HsnSacCode.item : "-"}
                    </TableContentFont>
                  </TableCell>

                  <TableCell>
                    <TableContentFont>
                      {row.createdAt !== null
                        ? moment(row.createdAt).format("DD MMM YY")
                        : "Not Applicable"}
                    </TableContentFont>
                  </TableCell>
                  {/* <TableCell style={{ textAlign: "center" }}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Link
                          style={{ textDecoration: "none" }}
                          // eslint-disable-next-line
                          to={"/app/item/" + `${row.id}`}
                        >
                          <RemoveRedEye
                            style={{
                              fill: "royalblue",
                              height: "18px",
                              width: "18px",
                            }}
                          />
                        </Link>
                      </Grid>
                    </Grid>
                  </TableCell> */}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                <CustomTableContainer>
                  <EmptyContainer text={`There are no items to display`} />
                </CustomTableContainer>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Grid
        container
        className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
        sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
      >
        <Grid className="d-flex align-items-center gap-2">
          <span>Rows per page: </span>
          <select
            value={per_page}
            onChange={(e) => setPer_page(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </Grid>
        <Pagination
          sx={{
            width: "max-content",
          }}
          color="primary"
          variant="text"
          onChange={(_, newValue) => setPage(newValue)}
          count={pagination?.total_page}
          page={page}
          shape="rounded"
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Grid>
    </CustomTableContainer>
  );
}

export default ItemTableContent;
